import { emit } from 'process'

export const BOSSCAT_PHONE_NUMBER = '+1-877-426-7722'

export const AUTH0_CODES = {
  INVALID_PASSWORD: 'invalid_password',
  INVALID_SIGNUP: 'invalid_signup',
}

export enum EMAIL_TYPE {
  PRIMARY = 'PRIMARY',
  CC = 'CC',
  BCC = 'BCC',
}

export enum USER_TYPE {
  HOMEOWNER = 'HOMEOWNER',
  HOME_BUYER = 'HOME_BUYER',
  HOME_SELLER = 'HOME_SELLER',
  BROKER = 'BROKER',
  INSTITUTIONAL = 'INSTITUTIONAL',
  INSPECTOR = 'INSPECTOR',
  OTHER = 'OTHER',
  CLOSING_COORDINATOR = 'CLOSING_COORDINATOR',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}

export enum ORDER_STATUS {
  APPROVED = 'APPROVED',
  DISPATCHED = 'DISPATCHED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
}

export enum ORDER_ITEM_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  APPROVED = 'APPROVED',
}

export enum ORDER_FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT',
}

export enum ORDER_ITEM_IMAGE_TYPE {
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  PRO_IMAGE = 'PRO_IMAGE',
}

export enum RESOURCE_TYPE {
  ESTIMATE = 'ESTIMATE',
  JOB = 'JOB',
}

export enum ESTIMATE_STATUS {
  NEW = 'NEW',
  COMPOSING = 'COMPOSING',
  IN_REVIEW = 'IN_REVIEW',
  RELEASED = 'RELEASED',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
}

export enum ESTIMATE_PUBLIC_STATUS {
  IN_PROGRESS = 'In Progress',
  EXPIRED = 'Expired',
  COMPLETED = 'Completed',
  APPROVED = 'Approved',
  // READY_FOR_RELEASE = 'Ready_for_release',
  OCR_PROCESSING = 'Ocr_processing',
}

export enum ITEM_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  NEW = 'NEW',
}

export enum ITEM_CATEGORY {
  ELECTRICAL = 'Electrical',
  PLUMBING = 'Plumbing',
  HVAC = 'HVAC',
  WINDOWS_DOORS = 'Windows/Doors',
  MISCELLANEOUS = 'Miscellaneous',
  CL100 = 'CL100',
  CRAWLSPACE = 'Crawlspace',
  EXTERIOR = 'Exterior',
  INTERIOR = 'Interior',
  ATTIC = 'Attic',
  ROOF = 'Roof',
  UNCATEGORIZED = 'UNCATEGORIZED',
  EVALUATE = 'EVALUATE',
  PERMITS_AND_FEES = 'Permits and Fees',
  EXCLUDED = 'Excluded',
}

export enum ITEM_CATEGORY_FOR_FILTERS {
  ELECTRICAL = 'Electrical',
  PLUMBING = 'Plumbing',
  HVAC = 'HVAC',
  WINDOWS_DOORS = 'Windows/Doors',
  MISCELLANEOUS = 'Miscellaneous',
  CL100 = 'CL100',
  CRAWLSPACE = 'Crawlspace',
  EXTERIOR = 'Exterior',
  INTERIOR = 'Interior',
  ATTIC = 'Attic',
  ROOF = 'Roof',
  UNCATEGORIZED = 'UNCATEGORIZED',
  EVALUATE = 'EVALUATE',
  PERMITS_AND_FEES = 'Permits and Fees',
  EXCLUDED = 'Excluded',
  ALL = 'All',
}

export enum ITEM_PRIORITY {
  All = 'All',
  Urgent = 'Urgent',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Cosmetic = 'Cosmetic',
}

export enum JOB_STATUS {
  SCHEDULING = 'SCHEDULING',
  DEPOSIT_PENDING = 'DEPOSIT_PENDING',
  DISPATCH_PENDING = 'DISPATCH_PENDING',
  DISPATCHED = 'DISPATCHED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  RECALLED = 'RECALLED',
}

export enum JOB_PAYMENT_STATUS {
  NOT_INVOICED = 'NOT_INVOICED',
  INVOICE_SENT = 'INVOICE_SENT',
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  PAID = 'PAID',
  IN_COLLECTION = 'IN_COLLECTION',
  COMPLETE = 'COMPLETE',
  RECALLED = 'RECALLED',
}

export enum JOB_PUBLIC_STATUS {
  SCHEDULING = 'Scheduling',
  DISPATCHED = 'Dispatched',
  HOLD = 'Hold',
  IN_PROGRESS = 'In Progress',
  NEEDS_ATTENTION = 'Needs Attention',
  COMPLETED = 'Completed',
}

export enum JOB_ITEM_PUBLIC_STATUS {
  SCHEDULED = 'Scheduled',
  DISPATCHED = 'Dispatched',
  IN_PROGRESS = 'In Progress',
  HOLD = 'Hold',
  COMPLETED = 'Completed',
  NEEDS_ATTENTION = 'Needs Attention',
}

export enum FORM_TYPE {
  CLIENT_INSPECTION_REPORT = 'CLIENT_INSPECTION_REPORT',
  CLIENT_REPAIR_LIST = 'CLIENT_REPAIR_LIST',
  INTERNAL = 'INTERNAL',
  LEGACY_FORM = 'LEGACY_FORM',
}

export enum REPAIR_TIMELINE {
  PRELISTING = 'PRELISTING',
  ASAP = 'ASAP',
  BEFORE_CLOSE = 'BEFORE_CLOSE',
  AFTER_CLOSE = 'AFTER_CLOSE',
  NEVER = 'NEVER',
  ONE_WEEK = 'ONE_WEEK',
  THIRTY_DAYS = 'THIRTY_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
  OVER_SIXTY_DAYS = 'OVER_SIXTY_DAYS',
}

export enum CONTACT_ROLE {
  LISTING_AGENT = 'LISTING_AGENT',
  BUYERS_AGENT = 'BUYERS_AGENT',
  BUYERS_CLOSING_COORDINATOR = 'BUYERS_CLOSING_COORDINATOR',
  SELLERS_CLOSING_COORDINATOR = 'SELLERS_CLOSING_COORDINATOR',
  HOMEOWNER = 'HOMEOWNER',
  AGENT_HOMEOWNER = 'AGENT_HOMEOWNER',
  INSPECTOR = 'INSPECTOR',
  OTHER = 'OTHER',
  HOME_BUYER = 'HOME_BUYER',
  HOME_SELLER = 'HOME_SELLER',
  INSTITUTIONAL_INVESTOR = 'INSTITUTIONAL_INVESTOR',
}

export enum DOCUSING_CONTRACT_TYPE {
  APPROVAL_PAY_AT_CLOSE = 'APPROVAL_PAY_AT_CLOSE',
  APPROVAL_PAY_ON_COMPLETION_UNDER_THRESHOLD = 'APPROVAL_PAY_ON_COMPLETION_UNDER_THRESHOLD',
  APPROVAL_PAY_ON_COMPLETION = 'APPROVAL_PAY_ON_COMPLETION',
  APPROVAL_REPAIRS_AFTER_CLOSING_DATE = 'APPROVAL_REPAIRS_AFTER_CLOSING_DATE',
  APPROVAL_LISTING_ACKNOWLEDGEMENT = 'APPROVAL_LISTING_ACKNOWLEDGEMENT',
}

export enum ESTIMATE_CONTACT_RELATION {
  CO_HOMEOWNER = 'CO_HOMEOWNER',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  REAL_ESTATE_AGENT = 'REAL_ESTATE_AGENT',
  FAMILY_FRIEND = 'FAMILY_FRIEND',
  OTHER = 'OTHER',
}

export const SHARE_ROLE = [
  {
    role: 'HOMEOWNER',
    label: 'Homeowner',
  },
  {
    role: 'HOME_BUYER',
    label: 'Homebuyer',
  },
  {
    role: 'LISTING_AGENT',
    label: 'Listing Agent',
  },
  {
    role: 'BUYERS_AGENT',
    label: "Buyer's Agent",
  },
  {
    role: 'BUYERS_CLOSING_COORDINATOR',
    label: "Buyer's Closing Coordinator",
  },
  {
    role: 'SELLERS_CLOSING_COORDINATOR',
    label: "Seller's Closing Coordinator",
  },
  {
    role: 'OTHER',
    label: 'Other',
  },
]

export enum APPROVAL_TYPE {
  NOT_NEEDED = 'NOT_NEEDED',
  REQUIRED = 'REQUIRED',
  APPROVED = 'APPROVED',
  PAY_AT_CLOSE = 'PAY_AT_CLOSE',
}

export enum FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT',
  PRO_IMAGE = 'PRO_IMAGE',
  REPAIR_LIST = 'REPAIR_LIST',
  CLIENT_CONTRACT = 'CLIENT_CONTRACT',
  LISTING_AGREEMENT = 'LISTING_AGREEMENT',
  COMPARABLE_MARKET_ANALYSIS = 'COMPARABLE_MARKET_ANALYSIS',
  MORE_ITEMS_REQUEST_IMAGE = 'MORE_ITEMS_REQUEST_IMAGE',
}

export enum PAYMENT_METHOD_TYPE {
  CARD = 'card',
}

export enum PAYMENT_METHOD_BRAND {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export enum ESTIMATE_VIEW {
  TYPE = 'Type',
  PRIORITY = 'Priority',
  APPROVED = 'Approved',
}

export enum JOB_VIEW {
  CATEGORY = 'category',
  STATUS = 'status',
}

export enum FEEDBACK_TYPE {
  ORDER = 'ORDER',
  ESTIMATE = 'ESTIMATE',
  ITEM = 'ITEM',
  SITE_VISIT = 'SITE_VISIT',
  JOB = 'JOB',
}

export enum PAYMENT_TYPE {
  CHECK = 'CHECK',
  CREDITCARD = 'CREDITCARD',
  ACH = 'ACH',
}

export enum PAYMENT_RESOURCE_TYPE {
  ORDER = 'ORDER',
  ESTIMATE = 'ESTIMATE',
  ITEM = 'ITEM',
  SITE_VISIT = 'SITE_VISIT',
  JOB = 'JOB',
  PAYMENT = 'PAYMENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum PAYMENT_STATUS {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum OCCUPANCY_STATUS {
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
}

export enum INSPECTION_STATUS {
  NEW = 'NEW',
}

export enum ESTIMATE_VIEW_COLORS_PRIORITY {
  Urgent = '#E01F1F',
  High = '#E01F1F',
  Medium = '#FFC633',
  Low = '#1CCD83',
  Cosmetic = '#5643D4',
}

export enum ESTIMATE_VIEW_COLORS_APPROVED {
  Approved = '#1CCD83',
  ApprovedBetter = '#1b8860',
  Rejected = '#E01F1F',
}

export const ESTIMATE_VIEW_COLORS_TYPES = [
  '#0E2E41',
  '#2B515D',
  '#4E7778',
  '#779E92',
  '#491A5F',
  '#7A23A3',
  '#BA72DB',
  '#5D55B4',
  '#3228AF',
  '#151050',
  '#EFBA32',
  '#BD8C0F',
  '#84620A',
  '#5F4607',
]

export const ESTIMATE_VIEW_BETTER_COLORS_TYPES = [
  '#073126',
  '#004733',
  '#99D6A9',
  '#CCE9D3',
  '#F0F7F1',
  '#BF271B',
  '#F2ABA6',
  '#F03333',
  '#F9B3B3',
  '#EF8032',
  '#F8C4A0',
  '#FFBC14',
  '#F8EBC9',
]

export enum PROTECT_PLAN_TYPES {
  PLAN_A = 'PLAN_A',
  PLAN_B = 'PLAN_B',
  PLAN_C = 'PLAN_C',
  PLAN_D = 'PLAN_D',
}

export enum SUSCRIPTION_STATUS {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
}

export const HOMETEAM_ROLES = [
  {
    label: 'Real Estate Agent',
    value: 'Real Estate Agent',
    key: 'LISTING_AGENT',
  },
  {
    label: 'Loan Officer',
    value: 'Loan Officer',
    key: 'LOAN_OFFICER',
  },
  {
    label: 'Property inspector',
    value: 'Property inspector',
    key: 'INSPECTOR',
  },
  {
    label: 'Insurance Agent',
    value: 'Insurance Agent',
    key: 'INSURANCE_AGENT',
  },
]

export const MONTH_OPTIONS = [
  {
    key: '1',
    value: 'January',
  },
  {
    key: '2',
    value: 'February',
  },
  {
    key: '3',
    value: 'March',
  },
  {
    key: '4',
    value: 'April',
  },
  {
    key: '5',
    value: 'May',
  },
  {
    key: '6',
    value: 'June',
  },
  {
    key: '7',
    value: 'July',
  },
  {
    key: '8',
    value: 'August',
  },
  {
    key: '9',
    value: 'September',
  },
  {
    key: '10',
    value: 'October',
  },
  {
    key: '11',
    value: 'November',
  },
  {
    key: '12',
    value: 'December',
  },
]

export const YEAR_OPTIONS = [
  {
    key: '1970',
    value: '1970',
  },
  {
    key: '1971',
    value: '1971',
  },
  {
    key: '1972',
    value: '1972',
  },
  {
    key: '1973',
    value: '1973',
  },
  {
    key: '1974',
    value: '1974',
  },
  {
    key: '1975',
    value: '1975',
  },
  {
    key: '1976',
    value: '1976',
  },
  {
    key: '1977',
    value: '1977',
  },
  {
    key: '1978',
    value: '1978',
  },
  {
    key: '1979',
    value: '1979',
  },

  {
    key: '1980',
    value: '1980',
  },
  {
    key: '1981',
    value: '1981',
  },
  {
    key: '1982',
    value: '1982',
  },
  {
    key: '1983',
    value: '1983',
  },
  {
    key: '1984',
    value: '1984',
  },
  {
    key: '1985',
    value: '1985',
  },
  {
    key: '1986',
    value: '1986',
  },
  {
    key: '1987',
    value: '1987',
  },
  {
    key: '1988',
    value: '1988',
  },
  {
    key: '1989',
    value: '1989',
  },

  {
    key: '1990',
    value: '1990',
  },
  {
    key: '1991',
    value: '1991',
  },
  {
    key: '1992',
    value: '1992',
  },
  {
    key: '1993',
    value: '1993',
  },
  {
    key: '1994',
    value: '1994',
  },
  {
    key: '1995',
    value: '1995',
  },
  {
    key: '1996',
    value: '1996',
  },
  {
    key: '1997',
    value: '1997',
  },
  {
    key: '1998',
    value: '1998',
  },
  {
    key: '1999',
    value: '1999',
  },
  {
    key: '2000',
    value: '2000',
  },
  {
    key: '2001',
    value: '2001',
  },
  {
    key: '2002',
    value: '2002',
  },
  {
    key: '2003',
    value: '2003',
  },
  {
    key: '2004',
    value: '2004',
  },
  {
    key: '2005',
    value: '2005',
  },
  {
    key: '2006',
    value: '2006',
  },
  {
    key: '2007',
    value: '2007',
  },
  {
    key: '2008',
    value: '2008',
  },
  {
    key: '2009',
    value: '2009',
  },
  {
    key: '2010',
    value: '2010',
  },
  {
    key: '2011',
    value: '2011',
  },
  {
    key: '2012',
    value: '2012',
  },
  {
    key: '2013',
    value: '2013',
  },
  {
    key: '2014',
    value: '2014',
  },
  {
    key: '2015',
    value: '2015',
  },
  {
    key: '2016',
    value: '2016',
  },
  {
    key: '2017',
    value: '2017',
  },
  {
    key: '2018',
    value: '2018',
  },
  {
    key: '2019',
    value: '2019',
  },
  {
    key: '2020',
    value: '2020',
  },
  {
    key: '2021',
    value: '2021',
  },
  {
    key: '2022',
    value: '2022',
  },
  {
    key: '2023',
    value: '2023',
  },
]

export const MONTH_OPTIONS_SHORT = [
  {
    key: '1',
    value: 'Jan',
  },
  {
    key: '2',
    value: 'Feb',
  },
  {
    key: '3',
    value: 'Mar',
  },
  {
    key: '4',
    value: 'Apr',
  },
  {
    key: '5',
    value: 'May',
  },
  {
    key: '6',
    value: 'Jun',
  },
  {
    key: '7',
    value: 'Jul',
  },
  {
    key: '8',
    value: 'Aug',
  },
  {
    key: '9',
    value: 'Sep',
  },
  {
    key: '10',
    value: 'Oct',
  },
  {
    key: '11',
    value: 'Nov',
  },
  {
    key: '12',
    value: 'Dec',
  },
]

export const YEAR_APPLIANCES_OPTIONS = () => {
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = 0; i < 100; i++) {
    years.push({
      key: currentYear - i,
      value: (currentYear - i).toString(),
    })
  }
  return years
}