import { StatusType } from 'components/pages/Desktop/OrderMaintenance/components/GiftServiceItem'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  ITEM_CATEGORY,
  ITEM_STATUS,
  ESTIMATE_PUBLIC_STATUS,
  ESTIMATE_STATUS,
  FILE_TYPE,
  FORM_TYPE,
  JOB_PUBLIC_STATUS,
  JOB_STATUS,
  REPAIR_TIMELINE,
  JOB_ITEM_PUBLIC_STATUS,
  PAYMENT_TYPE,
  PAYMENT_RESOURCE_TYPE,
  PAYMENT_STATUS,
  JOB_PAYMENT_STATUS,
  USER_TYPE,
  PROTECT_PLAN_TYPES,
  SUSCRIPTION_STATUS,
  ESTIMATE_CONTACT_RELATION,
  DOCUSING_CONTRACT_TYPE,
} from '../helpers'
import { UserType } from './user/types'
import { ConditionType } from './properties/types'
import { Tag } from './homemart/types'

export interface PunchlistFile {
  id: string
  name: string
  description: string
  fileType: FILE_TYPE
  fileUrl: string
  uploadedOn: number
  uploadedBy: string
  fileName?: string
  uploadedAfterJob?: boolean
  uploadedByUserType?: string
  resourceId?: string
  resourceType?: string
}

export interface Item {
  orderId: string
  orderPublicId: string
  estimateId: string
  estimatePublicId: string
  itemId: string
  id?: string
  title: string
  category: ITEM_CATEGORY
  inspectionReportReference: string
  quantity: number
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: number
  margin: number
  status: ITEM_STATUS
  publicJobStatus: JOB_ITEM_PUBLIC_STATUS
  disclaimer: string
  severity: string
  inspectionReportNote: string
  requestedRepairNote: string
  fieldNote: string
  punchlistTechNote: string
  imageFiles: Array<PunchlistFile>
  siteVisitId: string
  estimatedPrice: number
  siteVisitNotes: string
  siteVisitComplete: boolean
  totalPrice: number
  totalCost: number
  totalLaborHours: number
  totalLaborCost: number
  totalMaterialCost: number
  unitCost: number
  unitPrice: number
  measurement: string
  uploaded?: boolean
  editable?: boolean
  htmlFiles?: File[]
  publicRepairNote?: string
  maxPriceRange?: number
  minPriceRange?: number
  addedPostDelivery?: boolean
  consultationRequestedDate?: string
  consultationRequested?: boolean
}

export interface RepairItem {
  id: string
  publicTitle: string
  margin: number
  unitCost: number
  unitPrice: number
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: number
  title: string
  category: ITEM_CATEGORY
  severity: any
  disclaimer: string
  searchIndex: string
  materials: any
  hideFromClient: boolean
  territoryId: string
  usageCount: number
  repairItem: any
  territory: any
  profit: number
  status: ITEM_STATUS
}

export interface Job {
  id: string
  estimateId: string
  properties: EstimateProperties
  status: JOB_STATUS
  publicStatus: JOB_PUBLIC_STATUS
  accessDetails: string
  approvalNotes: string
  payAtClose: boolean
  inCollection: boolean
  writtenOff: boolean
  percentItemsComplete: number
  createdOn: number
  createdBy: string
  dueOn: number
  startedOn: number
  expectedCompletedOn: number
  preferredCompletedOn: number
  completedOn: number
  completedBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  paymentStatus: JOB_PAYMENT_STATUS
}

export interface Order {
  orderId: string
  orderName: string
  orderPublicId: string
  statusType: string
  estimateId: string
  estimatePublicId: string
  scheduledStartTimestamp: number
  scheduledEndTimestamp: number
  bidPrice: number
  totalValue: null | number
  totalCost: null | number
  vendorId: string
  companyName: string
  companyContact: string
  assigneeId: null | number
  assigneeName: null | string
  address: Address
  checkedInBy: null | string
  checkedInTimestamp: null | number
  completedTimestamp: null | number
  completedBy: null | string
  submittedTimestamp: null | number
  type: string
  csmAcceptanceTimestamp: null | number
  acceptedBy: null | string
  adminFlags: null | string
  dispatchedTimestamp: null | number
  dispatchedBy: null | string
  pricingModel: PricingModel
  nextTask: null | string
  dispatchNotes: null | string
  holdNotes: null | string
  vendorAcceptedTimestamp: number | number
  incentives: null | string
  vendorPaymentTimestamp: null | number
  vendorPaidBy: null | string
  paymentType: null | string
  completedByName: null | string
  acceptedByName: null | string
  vendorPaidByName: null | string
  paymentAmount: null | string
  assignedByName: null | string
  assignedByAddress: null | string
  assignedByPicture: null | string
  incentiveTotalAmount: number
  incentiveStartDeadline: null | string
}

export interface PricingModel {
  itemMarginType: string
  itemMarginValue: null
  autoUpdate: null
  items: any[]
}

export interface EstimateContact {
  id: string
  contactId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  role: CONTACT_ROLE | USER_TYPE
  sendEstimate: boolean
  isPayer: boolean
  isRequester: boolean
  isScheduler: boolean
  approvalType: APPROVAL_TYPE
  approvedOn: number
  fullName: string
  preferredCommunicationMethods: Array<string>
  insertedOn?: number
  zipCode?: string
  tosAccepted?: boolean
  companyName?: string
  institutionalRole?: string
  userClientType?: string
  contractSignedOn?: number
  agentAcknowledgementSignedOn?: number
  approvalFlowInProgress?: boolean
}

export interface Address {
  city: string
  state: string
  line_1: string
  line_2: string
  zipCode: string
  latitude: number
  longitude: number
  county?: string
  country: string
  timeZone?: string
  fullAddress?: string
  googleUrl?: string
  id?: string
}

export interface Property {
  id: string
  archived: boolean
  userId: string
  address: Address
  createdOn: number
  files: PunchlistFile[]
  imageUrl: string
}

export interface PropertyDetail {
  address: Address
  propertySource7?: PropertySource7
  appliancesTypes: ApplianceType
  appliancesBrands: ApplianceBrands
  details: {
    address?: Address
    information?: Information
    greenReport?: GreenReport
    insights?: Insight[]
    roomCosts?: RoomCollection
    renovationEstimate?: Renovation
    roi?: number
    enhancedConditionGood?: number
    enhancedConditionBetter?: number
    enhancedConditionBest?: number
  }
  id: string
  imageUrl: string
  inquiredItems?: string[]
}

export interface GreenReport {
  rebateAmount: number
  taxIncentiveAmount: number
  total: number
  categories: Category[]
  numPotentialIncentives: number
}

export interface SubProductTypes {
  sub_product_type_id: number;
  sub_product_type_name: string;
}

export interface ApplianceBrands {
  product_type_id: number;
  product_type_name: string;
  sub_product_types: SubProductTypes[];
  brands: Brand[];
}

export interface Brand {
  brandId: number;
  brandName: string;
}


export interface ApplianceType {
  id: string;
  product_types: ProductTypes[];
}

export interface ProductTypes {
  product_type_id: number;
  product_type_name: string;
  sub_product_types: SubProductTypes[];
}

export interface SubProductTypes {
  sub_product_type_id: number;
  sub_product_type_name: string;
}

export interface PropertySource7 {
  address: string;
  appliances: Appliance[];
  created: Date;
  errors: string[];
  home_base_property_id: string;
  id: string;
  images: string[];
  pdf_url: string;
  property_uid: string;
  submitter_email: string;
  subscribed: string;
  updated: Date;
  warnings: string[];
  year_built: string;
}

export interface Appliance {
  id: string;
  created: string;
  updated: string;
  age: number;
  subscribed: boolean;
  images: ApplianceImage[];
  errors: any[];
  dimensions?: {
    depth_inches: number;
    height_inches: number;
    width_inches: number;
  }
  warnings: any[];
  refreshed: number;
  appliance_uid: string;
  property_uid: string;
  brand_id: number;
  brand_name: string;
  product_type_id: number;
  product_type_name: string;
  sub_product_type_id: number | null;
  sub_product_type_name: string | null;
  manufactured_month: number;
  manufactured_year: number;
  serial_number: string;
  model_number: string;
  recall_number: string | null;
  recall_data: RecallData[];
  expected_life: number;
  identified_manufactured_dates: IdentifiedManufacturedDates;
  product_type_data: any | null;
  remaining_life: number;
  appliance_risk_failure_score: number;
  appliance_risk_failure_score_if_recall_fixed: number | null;
  appliance_data: ApplianceData[];
  install_date?: string;
}

interface ApplianceData {
  id: string;
  field: string;
  value: string;
  s7_appliance_data_id: string | null;
}
interface IdentifiedManufacturedDates {
  id: string;
  month: number;
  years: number[];
  percent_likelihood: PercentLikelihood[];
}

interface PercentLikelihood {
  id: string;
  year: number;
  probability: string;
}

export interface RecallData {
  id: string;
  hazard: string;
  fix: string;
  exact_match: boolean;
  recall_number: string;
  due_to: string;
  recall_url: string;
  date_started_being_sold: string;
  date_stopped_being_sold: string;
  date_recalled: string;
  date_added: string;
};

interface ApplianceImage {
  id: string;
  url: string;
  content: string | null;
  description: string;
  created: string;
  source7_id: string | null;
  is_label: boolean;
  vision_data: VisionData;
}

interface VisionData {
  brand_id: number;
  brand_name: string;
  product_type_id: number;
  product_type_name: string;
  model_number: string;
  serial_number: string;
  review_recommended: number | null;
}

export interface Contact {
  name: string
  company: string
  phoneNumber: number
  email: string
}

export interface Condition {
  area: string
  score: string
}

export interface ConditionCollection {
  condition: Condition[]
  subject: number
  neighborhood: number
}

export interface Renovation {
  items: RenovationItem[]
  summary: string
  totalCostGood: number
  totalCostBetter: number
  totalCostBest: number
}

export interface RenovationItem {
  costGood: number
  costBest: number
  costBetter: number
  hasIncentive: boolean
  project: string
  quantity: number
  unit: string
}

export interface Category {
  name: string
  sample: string
  numPotentialIncentives: number
  potentialIncentiveAmount: number
}

export interface Insight {
  id: string;
  propertyId: string;
  clientId: string;
  customItem: boolean;
  item: string;
  installDate?: string;
  replacementDate: string | undefined;
  nextMaintenanceDate: string | undefined;
  costMaintenance: number | undefined;
  costReplacementGood: number;
  costReplacementBetter: number;
  costReplacementBest: number;
  monthsOverdue: number | undefined;
  requested: boolean | undefined;
  brand?: string;
  lifeExpectancy?: number;
  productAge?: number;
  remainingLife?: number;
  model?: string;
  serial?: string;
  manufactureDate?: string;
  labelImageUrl: string;
  productImagesUrl: string[];
  images: string[]
}

export interface RoomCollection {
  rooms: Room[]
  totalProjectCount: number
  totalCostGood: number
  totalCostBetter: number
  totalCostBest: number
}

export interface Room {
  room: string
  projectCount: number
  costGood: number
  costBetter: number
  costBest: number
}

export interface Information {
  marketValue: number
  lotSize: number
  bedrooms: number
  bathrooms: number
  livingArea: number
  pool: boolean
  assessedYear: string
  assessedValue: number
  yearBuilt: string
  parcelNumber: string
  floors: number
  neighborhood: string
  propertyType: string
}

export interface Territory {
  id: string
  title: string
  phone: string
  email: string
  zipCodes: string[]
  visible: boolean
  serviceable: boolean
  latitude: number
  longitude: number
}

export interface EstimateProperties {
  id?: string
  address: Address
  files?: PunchlistFile[]
  territory?: Territory
  publicId?: string
  affiliate?: string
  closingDate?: number | null
  contacts?: Array<Partial<EstimateContact>>
  userId: string
  clientNotes?: string
  clientRequestNotes?: string

  totalValue?: number
  taxRate?: number
  taxAmount?: number
  discount?: number
  minimum?: number
  evaluationFee?: number
  payAtClose?: boolean
  accessDetails?: string
  preferredCompletedOn?: number | null
  invoiceId?: string
  repairTimeline?: REPAIR_TIMELINE
  propertyToBeSold?: boolean
  nar?: boolean | null
  totalEstimated?: number | null
  totalWithTax?: number | null
  propertyImageUrl?: string | null
  fullAddress: string
  requester?: EstimateContact
  excludedFromReports: boolean

  propertyUnderContract?: boolean
  completedBeforeClosing?: boolean
  lockbox?: string
  approvalStep: number
  installmentThreshold?: number
  depositPercentage?: number
  propertyListed?: boolean
  preferredStartDate?: number
  contractType?: DOCUSING_CONTRACT_TYPE
  smsConsent?: boolean
  lockboxConsent?: boolean
  homeownershipConfirmation?: boolean
  approximateHomeEquity?: number
  priceMatched?: boolean
}

export interface Estimate {
  id: string
  properties: Partial<EstimateProperties>
  status: ESTIMATE_STATUS
  publicStatus: ESTIMATE_PUBLIC_STATUS
  createdOn: number
  createdBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  dueOn: number
  deliveredOn: number
  deliveryServiceId: string
  deliveryServiceCost: number
  formType: FORM_TYPE
  repairTimeline: REPAIR_TIMELINE
  approvedOn: number
  approvedBy: string
  approvalStatus: string
  propertyToBeSold: boolean
  clientNotes: string
  jobNeedsAttention?: boolean
  minPriceRange?: number | null
  maxPriceRange?: number | null
  promotionId: string | null
  calendlyEventId?: string | null

  shouldUpdateRole?: boolean
  success?: true
  sharedByEmail?: string
  sharedByName?: string
  marketingProperties?: {
    source?: string
    medium?: string
    campaign?: string
    content?: string
    promo?: string
  }
  lastDeliveredOn?: number
  dontCreateAccount?: boolean
}

export interface PriceItem {
  id: string
  publicTitle: string
  margin: number
  unitCost: number
  unitPrice: number
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: number
  title: string
  category: ITEM_CATEGORY
  severity: string
  disclaimer: string
}

export interface Payment {
  id: string
  createdOn: number
  createdBy: string
  type: PAYMENT_TYPE
  amount: number
  resourceId: string
  resourceType: PAYMENT_RESOURCE_TYPE
  status: PAYMENT_STATUS
  stripeSourceId: string
  stripeTransactionId: string
  stripePaymentId: string
  stripeFee: number
  payerEmail: string
  memo: string
  address: string
  isRefund: boolean
}

export interface PaymentOption {
  id: string
  title: string
  date: number
  day: string
  subtitle: string
  enable: boolean
  best: boolean
  price: number
  days: number
}

export interface EstimateService {
  id: string
  title: string
  date: number
  days: number
  cost: number
  serviceable: true
  vip: true
}

export interface Invoice {
  id: string
  total: number
  subtotal: number
  preTaxTotal: number
  tax: number
  taxRate: number
  amountDue: number
  amountPaid: number
  discount: number
  discountRate: number
  subtotalCompleted: number
  subtotalPending: number
  currency: string
  createdOn: number
  dueDate: number
  resourceId: string
  resourceType: string
  address: Address
  preparedFor: Partial<EstimateContact>
  outstandingBalance: number
}

export interface GiftBox {
  insertedOn: number
  message: string
  shippingAddress: Address
  trackingId: string
  deliveryDate: number
  expeditedShippingCost: number
}

export type Subscription = {
  id: string
  protectPlan: PROTECT_PLAN_TYPES | null
  autoRenew: boolean
  title: string
  services: SubscriptionsPlanService[]
  homeowner: Partial<UserType> | null
  purchaser: UserType | null
  propertyAddress: Address | null
  files: PunchlistFile[]
  status: SUSCRIPTION_STATUS | null
  price: number | null
  insertedOn: number | null
  expiresOn: number | null
  giftBox: GiftBox | null
  renewals: number | null
  gift: boolean
  stripeProductPriceId: string
  clientSecret: string
  servicePeriodMonths?: number
}

export type SubscriptionsPlan = {
  id: string
  protectPlan: PROTECT_PLAN_TYPES | null
  title: string
  description: string
  price: number
  stripeProductPriceId: string
  services: SubscriptionsPlanService[]
  validTerritoryIds: string[]
  servicePeriodMonths: number
  promoCodeForClient: string
}

export interface SubscriptionsPlanService {
  title: string
  startMonth: number
  status: StatusType
  orderId: string | null
  jobId: string | null
  requestedDate: string | null
  scheduledDate: string | null
}

export enum REFERRAL_TYPE {
  EMAIL = 'EMAIL',
  SALES_TEAM = 'SALES_TEAM',
  WEBSITE = 'WEBSITE',
  MARKETING = 'MARKETING',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  NAR = 'NAR',
  EMPLOYEE = 'EMPLOYEE',
  OTHER = 'OTHER',
}

export interface PropertyContact {
  id: string
  userId: string
  propertyId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  createdOn: string
  role: string
  companyName: string
  imageUrl: string
}

export interface PropertyActivityActiveData {
  estimateId: string
  estimateCreatedOn: number
  estimateCreatedBy: string
  estimateCreatedByName: string
  estimateDeliveredOn: number
  estimateLastDeliveredOn: number
  numItems: number
  publicId: string
  approvedOn: number
  jobId: string
  jobStartedOn: number
  jobCompletedOn: number
  customerOwed: number
}

export interface PropertyActivityHistoryData {
  estimateId: string
  estimateCreatedOn: number
  estimateCreatedBy: string
  estimateCreatedByName: string
  estimateDeliveredOn: number
  estimateLastDeliveredOn: number
  numItems: number
  publicId: string
  approvedOn: number
  jobId: string
  jobStartedOn: number
  jobCompletedOn: number
  customerOwed: number
}

export interface PropertyActivity {
  totalActiveEstimates: number
  totalActiveJobs: number
  totalCompletedJobs: number
  totalDueForMaintenance: number
  totalDueForReplacement: number
  totalProperties?: number
  activeData: PropertyActivityActiveData[]
  historyData: PropertyActivityHistoryData[]
}

export type Partner = {
  id: string
  title: string
  titleImage: string
  logo: string
  discount: string
  discountVerbiage: string
  description: string
  tags: Tag[]
  actionVerbiage: string
  actionUrl: string
  customerTypes: Tag[]
  active: boolean
  readableCompanyName: string
}
