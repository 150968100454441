import {
  bosscatLogo,
  bosscatLogoExpandable,
  bosscatLogoMellohome,
  construction,
  icons,
  newItem,
  obArrow,
  obFinishProfile,
  greyNorthEast
} from 'assets'
import HomeTeamIcon from '@material-ui/icons/SupervisedUserCircle'
import WelcomeVIPModal from 'components/pages/Desktop/OrderMaintenance/components/WelcomeVIPModal'
import { configActions, subscriptionsActions, userActions } from 'ducks/actions'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPropertyContacts,
  getSideMenuOpen,
  getUser,
  getUserPrimaryEmail,
  isRegisterCompleted,
} from '../../../../../ducks/selectors'
import { USER_TYPE, history, pathsToHideSideMenu } from '../../../../../helpers'
import {
  useIsMdScreen,
  useIsSmScreen,
  useIsXsScreen,
} from '../../../../../hooks'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  SvgIcon,
  Tooltip,
  Typography,
} from '../../../MaterialUI'
import SideMenuContactModal from '../SideMenuContactModal'
import MenuItem from './MenuItem'
import useStyles from './styles'
import { SideMenuProps, SideMenuSlideProps } from './types'
import HomeTeamCard from '../../molecules/HomeTeamCard'
import { isMobile } from 'react-device-detect'
import { TrackGoogleAnalyticsEvent } from 'helpers/google-analytics'

const SideMenuSlide: FC<SideMenuSlideProps> = ({
  children,
  smallScreen,
  open,
}) => {
  const classes = useStyles()

  if (smallScreen) {
    return (
      <Slide
        direction="right"
        timeout={{ enter: 600, exit: 300 }}
        in={!smallScreen || open}
      >
        {children}
      </Slide>
    )
  }
  return (
    <div className={open ? classes.sidenav : classes.sidenavClosed}>
      {children}
    </div>
  )
}

const SideMenu: FC<SideMenuProps> = ({
  options,
  subOptions,
  estimateOptions,
  showOrderMaintenance,
}) => {
  // const [open, setOpen] = useState(true)
  const [delay, setDelay] = useState(false)
  const user = useSelector(getUser)
  const userMail = useSelector(getUserPrimaryEmail)
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMellohome = user.userProperties.source === 'loandepot'

  const open = useSelector(getSideMenuOpen())
  const setOpen = (newValue: boolean) => {
    TrackGoogleAnalyticsEvent({
      category: "HomeBase",
      action: "side_menu_open",
      options: {
        email: userMail,
        sideMenuOpen: newValue
      }
    })
    dispatch(
      configActions.setConfigValue({ type: 'sideMenuOpen', value: newValue })
    )
  }

  const { id: propertyId } = history.getParamValues()

  const location = window.location.href
  const isPropertyDNAScreen =
    location.includes('/dashboard/') || location.includes('/home-team')
  const isHomemartScreen =
    location.includes('/homemart')

  const registerCompleted = useSelector(isRegisterCompleted)
  // const subscriptions = useSelector(getSubscriptions("subscriptions"))

  const push = history.usePush()
  const currentPath = history.getCurrentPath()
  const query = history.useQuery()
  const affiliate = query.get('affiliate')
  const hideSideMenu = pathsToHideSideMenu.some((path) =>
    currentPath?.includes(path)
  )

  const fetchSubscriptions = useCallback(() => {
    dispatch(subscriptionsActions.fetchSubscriptions({}))
  }, [dispatch])

  const homeTeam = useSelector(getPropertyContacts())

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen

  const handleListItemClick = (option: any) => {
    if (option.closeOnClick && smallScreen) setOpen(false)
    push('p/' + option.path)
    //push(option.path)
    // setOpen(false)
  }

  const openInspection = () => {
    window.open('https://pillartopost.com/bosscat/', '_blank')
  }

  const handleCloseMenu = () => {
    setOpen(false)
  }
  const handleOpenMenu = () => {
    setOpen(true)
  }
  const handleSeeAllHomeTeam = () => {
    push(`p/dashboard/${propertyId}/contacts`)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelay(open)
    }, 600)
    dispatch(configActions.setMenuOpen(open))
    return () => clearTimeout(timer)
  }, [open])

  useEffect(() => {
    fetchSubscriptions()
  }, [fetchSubscriptions])

  useEffect(() => {
    if (
      user &&
      user.clientType !== USER_TYPE.HOMEOWNER &&
      !user.affiliate &&
      affiliate
    ) {
      dispatch(
        userActions.updateUser(
          {
            affiliate: affiliate || '',
          },
          (succ) => { }
        )
      )
    }
  }, [user])

  return (
    <>
      {smallScreen && !open && !delay && !hideSideMenu && (
        <Fade in={!delay}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={handleOpenMenu}
          >
            <icons.Menu className={classes.menuIcon} style={isHomemartScreen ? { fill: "white" } : {}} />
          </IconButton>
        </Fade>
      )}
      {smallScreen && open && delay && (
        <Fade in={delay}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={handleCloseMenu}
          >
            <icons.ChevronLeft className={classes.menuIcon} />
          </IconButton>
        </Fade>
      )}
      <Box hidden={!smallScreen || !open} className={classes.slideMenuBack} />
      <SideMenuSlide smallScreen={smallScreen} open={open}>
        <Box
          className={`${smallScreen ? classes.rootMobile : classes.root} ${open ? classes.rootOpen : classes.rootClosed
            }`}
        >
          {!smallScreen && (
            <Box
              className={classes.arrowContainer}
              onClick={() => setOpen(!open)}
            >
              {open ? <icons.ArrowBackIos /> : <icons.ArrowForwardIos />}
            </Box>
          )}
          <Box
            className={classes.logo}
            onClick={() => !smallScreen && setOpen(!open)}
          >
            <img
              alt=""
              className={`${open ? classes.logoOpened : classes.logoClosed}`}
              src={
                open
                  ? isMellohome
                    ? bosscatLogoMellohome
                    : bosscatLogo
                  : bosscatLogoExpandable
              }
            />
          </Box>
          {/* <List className={classes.options} component="nav"> */}
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                option={option}
                registerCompleted={registerCompleted}
                setOpen={setOpen}
                open={open}
                smallScreen={smallScreen}
              />
            )
          })}
          {/* </List> */}
          {showOrderMaintenance ? (
            <Slide
              direction="right"
              in={showOrderMaintenance}
              mountOnEnter
              unmountOnExit
            >
              <List className={classes.options} component="nav">
                <ListItem
                  key={'maintenance'}
                  button
                  disabled={!registerCompleted}
                  className={
                    currentPath === '/p/maintenance'
                      ? classes.selectedOption
                      : classes.option
                  }
                  selected={currentPath === '/p/maintenance'}
                  onClick={() => handleListItemClick({ path: 'maintenance' })}
                >
                  <ListItemIcon className={classes.icon}>
                    <SvgIcon
                      component={icons.House}
                      style={{
                        color:
                          currentPath === '/p/maintenance'
                            ? 'var(--input-color)'
                            : 'inherit',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight:
                            currentPath === '/p/maintenance' ? 900 : 400,
                        }}
                      >
                        Maintenance
                      </Typography>
                    }
                  />
                  {open && (
                    <Box className={classes.newItemWrapper}>
                      <Typography className={classes.newItem}>NEW</Typography>
                      <img
                        alt=""
                        src={newItem}
                        style={{ width: '12px', height: '12px' }}
                        className={classes.newItemImg}
                      />
                    </Box>
                  )}
                </ListItem>
              </List>
            </Slide>
          ) : (
            <></>
          )}
          <Box className={classes.subOptions}>
            {subOptions && (
              <>
                <Divider className={classes.divider} />
                {subOptions.map((subOption, index) => {
                  return (
                    <React.Fragment key={index}>
                      {subOption.title && (
                        <Typography>{subOption.title}</Typography>
                      )}
                      <Button
                        type="submit"
                        variant={subOption.variant}
                        size="large"
                        disabled={!registerCompleted}
                        className={
                          subOption.variant === 'contained'
                            ? classes.button
                            : classes.buttonReverse
                        }
                        onClick={() => handleListItemClick(subOption)}
                        fullWidth
                      >
                        {subOption.text}
                      </Button>
                    </React.Fragment>
                  )
                })}
              </>
            )}
            {estimateOptions && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    smallScreen && setOpen(false)
                    push('estimate')
                    TrackGoogleAnalyticsEvent({
                      category: "HomeBase",
                      action: "estimate_left_menu_button_pressed",
                      options: {
                        email: userMail,
                      }
                    })
                  }}
                  className={classes.button}
                  // endIcon={<SvgIcon component={icons.Assignment} />}
                  startIcon={<SvgIcon component={icons.Assignment} />}
                  aria-label="Get an Estimate"
                >
                  {open ? 'Get an Estimate' : ''}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    smallScreen && setOpen(false)
                    openInspection()
                    TrackGoogleAnalyticsEvent({
                      category: "HomeBase",
                      action: "inspection_left_menu_button_pressed",
                      options: {
                        email: userMail,
                      }
                    })
                  }}
                  className={`${classes.button} ${classes.inspectionButton}`}
                  // endIcon={<SvgIcon component={icons.Assignment} />}
                  startIcon={<SvgIcon component={icons.Home} />}
                  aria-label="Get an Inspection"
                >
                  {open ? 'Get an Inspection' : ''}
                </Button>
              </>
            )}
            {showOrderMaintenance && (
              <Button
                variant="contained"
                onClick={() => {
                  smallScreen && setOpen(false)
                  TrackGoogleAnalyticsEvent({
                    category: "HomeBase",
                    action: "maintenance_left_menu_button_pressed",
                    options: {
                      email: userMail,
                    }
                  })
                  push('maintenance')
                }}
                className={`${classes.button} ${classes.maintenanceButton}`}
                startIcon={
                  <img src={construction} alt="Order Maintenance button" />
                }
              >
                {open ? 'Order Maintenance' : ''}
              </Button>
            )}
            {!registerCompleted && (
              <Box
                className={classes.titleContainer}
                onClick={() => {
                  smallScreen && setOpen(false)
                  push('u/register')
                }}
                style={{ cursor: 'pointer' }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Avatar
                    alt=""
                    src={obFinishProfile}
                    className={classes.iconJob}
                    variant="square"
                  />
                  <Avatar
                    alt=""
                    src={obArrow}
                    className={classes.iconArrow}
                    variant="square"
                  />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box className={classes.titleDetail}>
                    <Typography className={classes.smallInfo}>
                      Finish creating your profile to start using the App.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.options} style={{ marginTop: '24px' }}>
            {isPropertyDNAScreen &&
              (open ? (
                <Box className={classes.boxContainer}>
                  <Grid container direction="column" spacing={1}>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ marginBottom: '12px' }}
                    >
                      <Grid
                        item
                        container
                        spacing={1}
                        alignItems="center"
                        style={{ width: 'fit-content' }}
                      >
                        <Grid item style={{ display: 'flex' }}>
                          <HomeTeamIcon className={classes.newItemImg} />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.LgTitle}>
                            Home Team
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        style={{
                          width: 'fit-content',
                          gap: '4px',
                          cursor: 'pointer',
                        }}
                        onClick={handleSeeAllHomeTeam}
                      >
                        <Grid item>
                          <Typography
                            className={classes.MdTitle}
                            style={{ color: 'var(--text-text-tertiary)' }}
                          >
                            See All
                          </Typography>
                        </Grid>
                        <Grid item>
                          <img src={greyNorthEast} alt="grey north east" />
                        </Grid>
                      </Grid>
                    </Grid>
                    {homeTeam.slice(0, 2).map((member) => (
                      <HomeTeamCard isSmall={true} profile={member} />
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  width="60px"
                  height="60px"
                  bgcolor="var(--hardware-haze-100)"
                  style={{
                    boxShadow:
                      '0px 1px 1px rgba(9, 25, 49, 0.24), 0px 0px 1px rgba(9, 25, 49, 0.31)',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  justifyContent='center'
                  display='flex'
                  alignItems='center'
                  onClick={handleSeeAllHomeTeam}
                >
                  <HomeTeamIcon />
                </Box>
              ))}

          </Box>
          <Box className={classes.line} />
          {open && (
            <List className={classes.options} component="nav">
              <ListItem
                key={'shared-estimates'}
                button
                disabled={!registerCompleted}
                className={classes.option}
                selected={currentPath === 'shared-estimates'}
                onClick={() =>
                  handleListItemClick({ path: 'shared-estimates' })
                }
              >
                <ListItemIcon className={classes.icon}>
                  <SvgIcon component={icons.AssignmentInd} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Shared Estimates</Typography>}
                />
                <Tooltip
                  title={
                    'Shared Estimates are Estimates requested by another party that have been shared with you.'
                  }
                >
                  <icons.Info />
                </Tooltip>
              </ListItem>
            </List>
          )}
          <SideMenuContactModal open={open} onClick={() => setOpen(false)} />
        </Box>
      </SideMenuSlide>
      {/* Maintenance modal VIP */}
      {user?.protectPlanIsNew && <WelcomeVIPModal />}
    </>
  )
}

export default SideMenu
