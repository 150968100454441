import { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useStyles from '../styles'
import { PrivateLoader } from 'components/templates'
import HomeTeamCard from 'components/UI/CustomUI/molecules/HomeTeamCard'
import { history } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertyContacts, getPropertyId } from 'ducks/selectors'
import { propertyContactActions } from 'ducks/actions'
import AddIcon from '@material-ui/icons/Add'
import AddHomeTeamMemberModal from '../Modals/AddHomeTeamMemberModal'
import { PropertyContact } from 'ducks/types'
import DeleteHomeTeamMemberModal from '../Modals/DeleteHomeTeamMemberModal'
import { homeSpecialist } from 'assets/index'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const HomeTeam = () => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const { usePush } = history
  const push = usePush()

  const { id: propertyId } = history.getParamValues()

  const homeTeam = useSelector(getPropertyContacts())

  const [loading, setLoading] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [profile, setProfile] = useState<PropertyContact | null>(
    {} as PropertyContact
  )

  const fetchHomeTeam = () => {
    setLoading(true)

    dispatch(
      propertyContactActions.fetchPropertyContacts(
        { id: propertyId },
        (succ) => {
          setLoading(false)
        }
      )
    )
  }

  useEffect(() => {
    fetchHomeTeam()
  }, [])

  const handleClickBack = () => {
    push('p/dashboard')
  }

  const handleAddNewMember = () => {
    setIsEdit(false)
    setProfile(null)
    setAddModalOpen(true)
  }

  const handleEditModal = (member: PropertyContact) => {
    setIsEdit(true)
    setProfile(member)
    setAddModalOpen(true)
  }

  const handleDeleteModal = (member: PropertyContact) => {
    setProfile(member)
    setDeleteModalOpen(true)
  }

  return (
    <PrivateLoader loading={loading}>
      <Grid style={{ padding: '24px 30px 0px 20px', width: '100%' }} spacing={3} container direction='column'>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Button className={styles.arrowButton} onClick={handleClickBack}>
              <ArrowBackIcon style={{ width: '16px', height: '16px' }} />{' '}
            </Button>
          </Grid>
          <Grid item>
            <Typography className={styles.xlFont}>YOUR HOME TEAM</Typography>
          </Grid>
        </Grid>
        <HolidayBanner />
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={styles.addButton}
            startIcon={
              <AddIcon
                style={{ width: '16px', height: '16px' }}
                color="inherit"
              />
            }
            onClick={handleAddNewMember}
          >
            <Typography
              className={styles.addPropertyText}
              style={{ textTransform: 'none' }}
            >
              Add New Member
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <HomeTeamCard
            isSmall={false}
            profile={{
              companyName: 'BOSSCAT Home Services',
              email: 'customercare@bosscathome.com',
              phone: '1-877-426-77228',
              imageUrl: homeSpecialist,
              role: "Property Advisor",
              firstName: 'Jackie',
              lastName: '',
            } as PropertyContact}
            onEdit={handleEditModal}
            onDelete={handleDeleteModal}
            isBosscat
          />
        </Grid>
        <Grid item container spacing={2}>
          {homeTeam.map((profile) => (
            <Grid item xl={6} lg={12}>
              <HomeTeamCard
                isSmall={false}
                profile={profile}
                onEdit={handleEditModal}
                onDelete={handleDeleteModal}
                showButton
              />
            </Grid>
          ))}
        </Grid>
        <AddHomeTeamMemberModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          isEdit={isEdit}
          profile={profile}
        />
        <DeleteHomeTeamMemberModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          profile={profile}
        />
      </Grid>
    </PrivateLoader>
  )
}

export default HomeTeam
