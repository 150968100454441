import { authTypes } from 'ducks/auth/types'
import {
  newOrderMaintenanceTypes,
  NewOrderMaintenanceType,
  NewOrderMaintenanceActions,
  NewOrderMaintenanceValue,
  NewOrderMaintenanceValues,
} from './types'

const initialState: NewOrderMaintenanceType = {
  step: -2, // first step
  mobileStep: 0,
  homeownerReceives: true,
  id: '',
  protectPlan: null,
  autoRenew: true,
  title: "",
  services: [],
  homeowner: null,
  purchaser: null,
  showSurvey: false,
  propertyAddress: null,
  files: [],
  status: null,
  price: null,
  insertedOn: null,
  expiresOn: null,
  contractSigned: null,
  giftBox: null,
  renewals: null,
  gift: false,
  promoCode: null,
  promo: null,
  mainContact: {},
  clientType: '',
  clientSecret: '',
  userIsloggedIn: false,
  redirectAfterLogin: false,
  preferredCommunicationPhone: true,
  preferredCommunicationText: true,
  preferredCommunicationMail: true,
  showLoading: false,
}

const reducer = (
  state = initialState,
  action: NewOrderMaintenanceActions
): NewOrderMaintenanceType => {
  const { type, payload } = action
  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return {...initialState, redirectAfterLogin: state.redirectAfterLogin}
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case newOrderMaintenanceTypes.GO_BACK: {
      return {
        ...state,
        step: state?.step as number - 1,
        mobileStep: state?.mobileStep as number - 1,
      }
    }
    case newOrderMaintenanceTypes.GO_FORWARD: {
      return {
        ...state,
        step: state?.step as number + 1,
        mobileStep: state?.mobileStep as number + 1,
      }
    }
    case newOrderMaintenanceTypes.SET_VALUE: {
      const { attr, value } = payload as NewOrderMaintenanceValue
      return {
        ...state,
        [attr]: value,
      }
    }
    case newOrderMaintenanceTypes.SET_VALUES: {
      const { attrs } = payload as NewOrderMaintenanceValues
      return {
        ...state,
        ...attrs,
      }
    }

    case newOrderMaintenanceTypes.SET_ORDER_MAINTENANCE: {
      return payload as NewOrderMaintenanceType
    }

    case newOrderMaintenanceTypes.CLEAR: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
