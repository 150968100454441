import { Box, Dialog, Grid, SvgIcon, Tab, Tabs, Typography } from '@material-ui/core'
import { Appliance } from 'ducks/types'
import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import RiskScoreHorizontal from '../RiskScoreHorizontal'
import Info from '@material-ui/icons/Info'
import { icons } from 'assets'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { propertyDetailActions } from 'ducks/actions'
import { getPropertyApplianceBrands } from 'ducks/selectors'
import { Autocomplete } from 'components/UI'
import { history } from 'helpers/index'
import { toast } from 'react-toastify'
import useStyles from '../../../../../styles'
import { TabPanel } from 'components/pages/Desktop/OrderMaintenance/Dashboard'
import { useIsLgScreen, useIsMdScreen } from 'hooks/useMediaQuery'
import { TurnedIn } from '@material-ui/icons'

type Props = {
    open: boolean
    setOpen: (open: boolean) => void
    appliance: Appliance
}

const HAZARD_CHARS_LIMIT = 550

const ApplianceDetail = ({ open, setOpen, appliance }: Props) => {
    const [isEditing, setIsEditing] = React.useState(false)
    const [brandName, setBrandName] = React.useState({ key: appliance?.brand_name, value: appliance?.brand_name })
    const [modelNumber, setModelNumber] = React.useState(appliance?.model_number)
    const [serialNumber, setSerialNumber] = React.useState(appliance?.serial_number)
    const [manufacturedDate, setManufacturedDate] = React.useState(`${('0' + appliance?.manufactured_month).slice(-2)}-${appliance?.manufactured_year}`)
    const [age, setAge] = React.useState(appliance?.age)
    const [installDate, setInstallDate] = React.useState(appliance?.install_date)
    const [loadingDelete, setLoadingDelete] = React.useState(false)
    const [loadingEdit, setLoadingEdit] = React.useState(false)
    const [showAllHazard, setShowAllHazard] = React.useState(false)
    const [manufacturedDateError, setManufacturedDateError] = React.useState('');
    const [installDateError, setInstallDateError] = React.useState('')
    const [ageError, setAgeError] = React.useState('')
    const [currentTab, setCurrentTab] = React.useState<number>(0)


    const [isLgScreen] = useIsLgScreen()
    const [isMdScreen] = useIsMdScreen()

    const { id: propertyId } = history.getParamValues()

    const mobileStyles = useStyles()

    const dispatch = useDispatch()

    const brands = useSelector(getPropertyApplianceBrands())
    const brandsOptions = brands?.brands?.map((brand) => ({ key: brand.brandName, value: brand.brandName }))

    const fetchBrands = (categoryId: string) => {
        dispatch(
            propertyDetailActions.fetchAppliancesBrands({ id: categoryId })
        )
    }

    const handleEdit = () => {
        if (isEditing) {
            return handleEditAppliance()
        }
        setIsEditing(!isEditing)
    }

    // const handleChangeBrand = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setBrandName(e.target.value)
    // }

    const handleChangeModel = (e: React.ChangeEvent<HTMLInputElement>) => {
        setModelNumber(e.target.value)
    }

    const handleChangeSerial = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSerialNumber(e.target.value)
    }

    const handleChangeAge = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAge(Number(e.target.value))
    }

    const handleChangeInstallDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setInstallDate(inputValue);
    }

    const handleChangeManufactured = (e: React.ChangeEvent<HTMLInputElement>) => {
        setManufacturedDate(e.target.value)
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setCurrentTab(newValue)
    }

    const handleDeleteAppliance = () => {
        setLoadingDelete(true)
        dispatch(propertyDetailActions.deleteAppliance({ propertyId: propertyId, applianceId: appliance?.appliance_uid }, (succ) => {
            if (succ) {
                fetchPropertySource7()
                toast.success('Appliance deleted successfully')
                setOpen(false)
            } else {
                toast.error('Error deleting appliance')
            }
            setLoadingDelete(false)
        })
        )
    }

    const fetchPropertySource7 = () => {
        dispatch(
            propertyDetailActions.fetchPropertySource7({ id: propertyId })
        )
    }

    const handleEditAppliance = () => {
        const firstPartManufacturedDate = Number(manufacturedDate.split('-')[0]);
        const secondPartManufacturedDate = Number(manufacturedDate.split('-')[1]);
        const firstPartInstallDate = Number(installDate?.split('-')[0]);
        const secondPartInstallDate = Number(installDate?.split('-')[1]);
        if (isNaN(age)) {
            return setAgeError("Please just use numbers")
        }
        if (`${`0${firstPartInstallDate}`.slice(-2)}`.toString().length !== 2 || `${secondPartInstallDate}`.toString().length !== 4) {
            return setInstallDateError("Wrong format. Please fix it to MM-YYYY");
        } else {
            setInstallDateError('')
        }
        if (`${`0${firstPartManufacturedDate}`.slice(-2)}`.length !== 2 || `${secondPartManufacturedDate}`.toString().length !== 4) {
            return setManufacturedDateError("Wrong format. Please fix it to MM-YYYY");
        } else {
            setManufacturedDateError('')
        }
        setLoadingEdit(true)
        dispatch(
            propertyDetailActions.confirmAppliance({
                applianceId: appliance?.appliance_uid,
                brand_name: brandName.key,
                model_number: modelNumber,
                serial_number: serialNumber,
                manufacturedMonth: Number(manufacturedDate.split('-')[0]),
                manufacturedYear: Number(manufacturedDate.split('-')[1]),
                age: age,
                install_date: installDate,
                property_uid: appliance?.property_uid,
                propertyId: propertyId,
                productTypeId: appliance?.product_type_id,
                brand_id: brands.brands.find((brand) => brand.brandName === brandName.key)?.brandId as number
            }, (succ, result) => {
                if (succ) {
                    fetchPropertySource7()
                    toast.success('Appliance updated successfully')
                    setOpen(false)
                } else {
                    toast.error('Error updating appliance')
                }
                setInstallDateError('')
                setManufacturedDateError('')
                setIsEditing(false)
                setLoadingEdit(false)
            })
        )
    }

    const handleOpenLink = (url: string) => {
        window.open(url, '_blank')
    }


    useEffect(() => {
        if (appliance) {
            setBrandName({
                key: appliance?.brand_name,
                value: appliance?.brand_name
            })
            setModelNumber(appliance?.model_number)
            setSerialNumber(appliance?.serial_number)
            setManufacturedDate(`${('0' + appliance?.manufactured_month).slice(-2)}-${appliance?.manufactured_year}`)
            setAge(appliance?.age)
            setInstallDate(appliance?.install_date)
            fetchBrands(`${appliance?.product_type_id}`)
        }
    }, [appliance])

    return (
        <Dialog open={open} onClose={() => {
            setOpen(false)
            setIsEditing(false)
        }} maxWidth="md">
            <Box className={styles.header}>
                <Typography className={styles.iconLabel}>{appliance?.images[0]?.vision_data?.product_type_name ||
                    appliance?.product_type_name}</Typography>
                <Box className={styles.closeIconContainer} onClick={() => {
                    setOpen(false)
                    setIsEditing(false)
                }
                }>
                    <SvgIcon component={icons.Close} />
                </Box>
            </Box>
            <Tabs
                style={{
                    width: 'fit-content',
                    padding: 0,
                }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: 'var(--bosscat-blue-900)',
                        zIndex: '-1',
                        height: '100%',
                        borderRadius: '8px',
                    },
                }}
                classes={{ root: styles.navigationTabsStyle }}
                value={currentTab}
                onChange={handleTabChange}
                className={styles.navigationTabs}
                TabScrollButtonProps={{ style: { height: '44px' } }}
            >
                <Tab label="Report" className={mobileStyles.mobileNavTab} />
                <Tab label="Images" className={mobileStyles.mobileNavTab} />
                {appliance?.recall_data && appliance?.recall_data?.length > 0 && <Tab label="Recall" className={mobileStyles.mobileNavTab} />}

            </Tabs>
            <Box display={"flex"} justifyContent={"space-between"}>
                <TabPanel index={0} value={currentTab}>
                    <Grid container direction="column" className={styles.container}>
                        <Box width={"100%"}>
                            <Typography className={styles.riskLabel}>Failure Risk Score</Typography>
                            <RiskScoreHorizontal score={appliance?.appliance_risk_failure_score} />
                        </Box>
                        <Box className={styles.estimatedLifeContainer}>
                            <Info className={styles.estimatedLifeIcon} />
                            <Typography className={styles.estimatedLifeText}>{`Estimated Remaining Life: ${Math.floor(appliance?.remaining_life)} ${Math.floor(appliance?.remaining_life) === 1 ? "year" : "years"}`}</Typography>
                        </Box>
                        <Box className={styles.applianceData}>
                            <Box className={`${styles.inputContainer} ${styles.fullWidth}`} style={isEditing ? {} : { pointerEvents: "none" }}>
                                <Typography className={styles.inputLabel}>Brand</Typography>
                                <Autocomplete
                                    popperWidth='30%'
                                    value={brandName}
                                    onChange={(e) => setBrandName(e as any)}
                                    options={brandsOptions || []}
                                />
                            </Box>
                            <Box className={styles.rowContainer}>
                                <Box className={styles.inputContainer}>
                                    <Typography className={styles.inputLabel}>Model #</Typography>
                                    <input className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`} value={`${modelNumber}`} onChange={handleChangeModel} />
                                </Box>
                                <Box className={styles.inputContainer}>
                                    <Typography className={styles.inputLabel}>Serial #</Typography>
                                    <input className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`} value={`${serialNumber}`} onChange={handleChangeSerial} />
                                </Box>
                            </Box>
                            {appliance?.dimensions && <Box className={styles.rowContainer}>
                                <Box className={`${styles.inputContainer} ${styles.fullWidth}`}>
                                    <Typography className={styles.inputLabel}>Dimensions</Typography>
                                    <Box className={`${styles.input} ${styles.inputDisabled}`}>
                                        <Typography style={{ fontSize: "14px" }}>{`${appliance?.dimensions.width_inches} x ${appliance?.dimensions?.depth_inches} x ${appliance?.dimensions?.height_inches}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>}
                            <Box className={styles.rowContainer}>
                                <Box className={styles.inputContainer}>
                                    <Typography className={styles.inputLabel}>Date Manufactured</Typography>
                                    <input className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`} value={manufacturedDate} onChange={handleChangeManufactured} />

                                </Box>
                                <Box className={styles.inputContainer}>
                                    <Typography className={styles.inputLabel}>Product Age</Typography>
                                    {!isEditing ? <Box className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`}>
                                        <Typography style={{ fontSize: "14px" }}>{`${Math.floor(appliance?.age)} ${Math.floor(appliance?.age) === 1 ? 'year' : "years"}`}</Typography>
                                    </Box> :
                                        <>
                                            <input placeholder="Age" className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`} type="number" value={`${Math.floor(age)}`} onChange={handleChangeAge} maxLength={3} />
                                        </>
                                    }
                                </Box>
                            </Box>
                            {(manufacturedDateError || ageError) && <Box className={styles.rowContainer}>
                                <Box className={styles.inputContainer}>
                                    {manufacturedDateError && <Typography className={styles.errorMessage}>{manufacturedDateError}</Typography>}
                                </Box>
                                <Box className={styles.inputContainer}>
                                    {ageError && <Typography className={styles.errorMessage}>{ageError}</Typography>}
                                </Box>
                            </Box>}
                            <Box className={styles.rowContainer}>
                                <Box className={styles.inputContainer}>
                                    <Typography className={styles.inputLabel}>Expected Life</Typography>
                                    <Box className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`}>
                                        <Typography style={{ fontSize: "14px" }}>{`${Math.floor(appliance?.expected_life)} ${Math.floor(appliance?.expected_life) === 1 ? 'year' : 'years'}`}</Typography>
                                    </Box>
                                    {installDateError && <Box style={{ height: "48px" }} />}
                                </Box>
                                <Box className={styles.inputContainer}>
                                    <Typography className={styles.inputLabel}>Install Date</Typography>
                                    <input placeholder='MM-YYYY' className={`${styles.input} ${isEditing ? "" : styles.inputDisabled}`} value={`${installDate || ""}`} onChange={handleChangeInstallDate} maxLength={7} />
                                    {installDateError && <Typography className={styles.errorMessage}>{installDateError}</Typography>}
                                </Box>
                            </Box>
                            <Box className={styles.buttonsContainer}>
                                <Button
                                    label={isEditing ? 'Cancel' : 'Delete Appliance'}
                                    onClick={isEditing ? () => setIsEditing(false) : handleDeleteAppliance}
                                    backgroundColor='var(--white-color)'
                                    labelColor='var(--bosscat-black-600)'
                                    loading={loadingDelete}
                                    disabled={loadingEdit || loadingDelete}
                                />
                                <Button
                                    label={isEditing ? 'Save Changes' : 'Edit Appliance'}
                                    loading={loadingEdit}
                                    disabled={loadingEdit || loadingDelete}
                                    onClick={handleEdit}
                                    startIcon={<SvgIcon component={isEditing ? icons.Save : icons.Edit} />}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel style={{ width: "100%" }} index={1} value={currentTab}>
                    <Box className={styles.titleContainer}>
                        <TurnedIn className={styles.iconTitle} />
                        <Typography className={styles.imageTitle}>APPLIANCE TAG</Typography>
                    </Box>
                    {appliance?.images?.[0]?.url ?
                        <Box onClick={() => handleOpenLink(appliance?.images?.[0]?.url)} className={styles.uploadImageContainer}>
                            <img alt="appliance tag" src={appliance?.images?.[0]?.url} className={styles.image} />
                        </Box>
                        :
                        <Box className={styles.uploadImageContainer}>
                            <TurnedIn className={styles.uploadIcon} />
                            <Typography align='center' className={styles.uploadText}>
                                APPLIANCE TAG PHOTO
                            </Typography>
                            <Typography align='center' className={styles.uploadSubtitle}>
                                Upload a product image via the BOSSCAT App to get information of the current state of your appliance.
                            </Typography>
                        </Box>}
                </TabPanel>
                {appliance?.recall_data && appliance?.recall_data?.length > 0 &&
                    <TabPanel index={2} value={currentTab}>
                        {appliance?.recall_data && appliance?.recall_data?.length > 0 &&
                            <Grid container direction="column" className={styles.container}>
                                <Typography className={styles.recallTitle}>
                                    RECALL INFORMATION
                                </Typography>
                                <Box className={styles.recallRow}>
                                    <Typography className={styles.recallLabel}>Recall Number</Typography>
                                    <Typography className={styles.recallValue}>{appliance?.recall_data[0]?.recall_number || '-'}</Typography>
                                </Box>
                                {appliance?.recall_data[0]?.due_to && <Box className={styles.recallRow}>
                                    <Typography className={styles.recallLabel}>Recall Due To</Typography>
                                    <Typography className={styles.recallValue}>{appliance?.recall_data[0]?.due_to || '-'}</Typography>
                                </Box>}
                                {appliance?.recall_data[0]?.recall_url && <Box className={styles.recallRow}>
                                    <Typography className={styles.recallLabel}>Recall Info</Typography>
                                    <Typography onClick={() => handleOpenLink(appliance?.recall_data[0]?.recall_url)} className={`${styles.recallValue} ${styles.recallLink}`} style={{ textDecoration: "underline" }}>View Details <SvgIcon fontSize='small' component={icons.CallMade} /> </Typography>
                                </Box>}
                                <Box className={styles.columnContainer}>
                                    <Typography className={styles.recallLabel}>Recall Hazard</Typography>
                                    <Typography className={styles.recallValue}>{showAllHazard ? appliance?.recall_data[0]?.hazard : `${appliance?.recall_data[0]?.hazard.slice(0, HAZARD_CHARS_LIMIT)}...` || '-'}</Typography>
                                    {appliance?.recall_data[0].hazard.length > HAZARD_CHARS_LIMIT && <Typography onClick={() => setShowAllHazard(prevshowAllHazard => !prevshowAllHazard)} className={styles.recallLink}>{showAllHazard ? 'View Less...' : 'View More...'}</Typography>}
                                </Box>
                                <Box className={styles.columnContainer}>
                                    <Typography className={styles.recallLabel}>Recall Fix</Typography>
                                    <Typography className={styles.recallValue}>{appliance?.recall_data[0]?.fix || '-'}</Typography>
                                </Box>
                            </Grid>}
                    </TabPanel>}
            </Box>
        </Dialog>
    )
}

export default ApplianceDetail