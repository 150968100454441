import { Promo } from 'ducks/promos/types'
import {
  Address,
  EstimateContact,
  Subscription
} from '../types'

/** TYPES**/
export const newOrderMaintenanceTypes = {
  SET_VALUE: 'SET_NEW_ORDER_MAINTENANCE_VALUE',
  SET_VALUES: 'SET_NEW_ORDER_MAINTENANCE_VALUES',
  GO_BACK: 'GO_BACK_NEW_ORDER_MAINTENANCE_STEP',
  GO_FORWARD: 'GO_FORWARD_NEW_ORDER_MAINTENANCE_STEP',
  CLEAR: 'CLEAR_NEW_ORDER_MAINTENANCE',
  SET_ORDER_MAINTENANCE: 'SET_NEW_ORDER_MAINTENANCE',
}

export interface NewOrderMaintenanceValue {
  attr: keyof NewOrderMaintenanceType
  value: any
}

/** ACTIONS**/

export interface NewOrderMaintenanceSetValueAction {
  type: typeof newOrderMaintenanceTypes.SET_VALUE
  payload: NewOrderMaintenanceValue
}

export interface NewOrderMaintenanceGoBackAction {
  type: typeof newOrderMaintenanceTypes.GO_BACK
  payload: null
}

export interface NewOrderMaintenanceValues {
  attrs: Partial<NewOrderMaintenanceType>
}

export interface NewOrderMaintenanceSetValuesAction {
  type: typeof newOrderMaintenanceTypes.SET_VALUES
  payload: NewOrderMaintenanceValues
}

export interface SetOrderMaintenance {
  type: typeof newOrderMaintenanceTypes.SET_VALUES
  payload: NewOrderMaintenanceType
}


export interface ClearOrderMaintenanceAction {
  type: typeof newOrderMaintenanceTypes.SET_VALUES
  payload: null
}


export interface ReplaceInterface {
  path: string,
  value: any
}

export type NewOrderMaintenanceActions =
  | NewOrderMaintenanceGoBackAction
  | NewOrderMaintenanceSetValueAction
  | NewOrderMaintenanceSetValuesAction
  | ClearOrderMaintenanceAction
  | NewOrderMaintenanceSetValuesAction
  | SetOrderMaintenance

/** REDUCER **/
export interface NewOrderMaintenanceType extends Partial<Subscription> {
  step?: number,
  mobileStep?: number
  contractSigned?: true | null
  promoCode?: string | null
  autoRenew?: boolean
  promo?: Promo | null
  homeownerReceives?: boolean
  priceLoading?: boolean
  mainContact: Partial<EstimateContact>
  clientType: string
  clientSecret: string
  userIsloggedIn: boolean
  redirectAfterLogin: boolean
  serviceable?: boolean
  preferredCommunicationPhone: boolean,
  preferredCommunicationText: boolean,
  preferredCommunicationMail: boolean,
  showSurvey?: boolean
  disableNext?: boolean
  showWelcome?: boolean
  showProgress?: boolean
  activeStepProgress?: number
  totalStepProgress?: number
  showLoading?: boolean
  marketingProperties?: {
    source: string
  }
}
