import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    orderSummaryContainer: {
      backgroundColor: '#F9FAFA',
      padding: '0 1rem',
      borderRadius: '8px'
    },
    orderSummaryTitle: {
      color: '#494A5A',
      fontFamily: 'LatoNormal'
    },
    bold: {
      fontFamily: 'LatoBold'
    },
    plan: {
      fontSize: '18px'
    },
    box: {
      padding: '0.5rem 0',
      margin: 0
    },
    padding: {
      padding: '1rem 0'
    },
    gift: {
      color: '#6E5BE8'
    },
    promoCode: {
      flex: 1
    },
    giftIcon: {
      fill: '#6E5BE8',
      marginRight: '.5rem',
      width: '1rem'
    },
    subscriptionPrice: {
      color: 'var(--head-text-color)',
    },
    subscriptionPriceHasPromo: {
      fontFamily: 'LatoNormal',
      textDecoration: 'line-through'
    },
    promoPrice: {
      color: 'var(--bosscat-blue-600)',
    },
    spinner: {
      margin: '1rem',
      display: 'flex',
      justifyContent: 'center'
    },
    switch: {
      width: '28px !important',
      height: '22px !important',
      padding: '2px 0px !important',
      // margin: '6px 5px',
      alignSelf: 'center',
      display: 'flex !important',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)'
        }
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        top: -16,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: 'var(--bosscat-blue-600)'
          }
        }
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200
        })
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
      }
    },
  })
)
