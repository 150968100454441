import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '24px',
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    table: {
      paddingRight: '24px',
      [theme.breakpoints.down('md')]: {
        padding: '0 12px',
      },
    },
    tableComponent: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12,
    },
    button: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)',
      },
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '16px',
    },
    description: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '12px',
    },
    row: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)',
      },
    },
    cellHead: {
      backgroundColor: 'var(--bosscat-blue-900)',
      color: 'white',
      padding: '8px',
      borderLeft: '2px solid var(--bosscat-grey-300)',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 900,
      lineHeight: '24px',
    },
    rowCellHead: {
      backgroundColor: 'var(--bosscat-blue-900)',
      color: 'white',
      padding: '8px',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 900,
      lineHeight: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
      border: '1px solid #EBECEF',
      padding: '8px !important',
    },
    icon: {
      fontSize: 16,
      marginRight: '10px',
      marginBottom: '3px',
    },
    active: {
      fontFamily: 'OutfitHeavy',
      fontWeight: 900,
      fontSize: '20px',
      textTransform: 'uppercase',
      lineHeight: '150%',
      [theme.breakpoints.down('xs')]: {
        lineHeight: 1,
        marginTop: '1rem',
      },
    },
    content: {
      marginTop: '32px',
      display: 'grid',
      alignContent: 'start',
    },
    noResultTitle: {
      fontSize: '38px',
      fontFamily: 'Lato !important',
      maxWidth: 'none !important',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        whiteSpace: 'normal',
      },
    },
    noResultDescription: {
      fontSize: '20px',
      fontFamily: 'Lato !important',
      maxWidth: 'none !important',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        whiteSpace: 'normal',
      },
    },
    noResultButtonsContainer: {
      display: 'flex',
      width: '80%',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        width: '100%',
        height: '96px',
      },
    },
    noResultViewButton: {
      width: '45%',
      height: '38px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    noResultViewButtonAlt: {
      width: '45%',
      height: '38px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--button-focus)',
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    ilustration: {
      width: '220px',
      alignSelf: 'center',
      margin: '0px 32px 0px 0px',
    },
    addButton: {
      border: '2px solid var(--border-border-info)',
      borderRadius: '8px',
      color: 'var(--text-text-info)',
    },
    addPropertyText: {
      fontFamily: 'LatoNormal',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '24px',
      color: 'var(--text-text-info)',
    },
    homeCard: {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '8px',
      zoom: 1,
      cursor: 'pointer',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      '&:hover': {
        boxShadow:
          '0px 0px 3px 0px rgba(9, 25, 49, 0.31), 0px 3px 3px 0px rgba(9, 25, 49, 0.24)',
      },
    },
    nextArtTitle: {
      fontSize: '16px',
      fontWeight: 900,
      lineHeight: '24px',
      textTransform: 'uppercase',
      // textAlign: 'center'
    },
    homeCardStatusBar: {
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '4px',
      width: '100%',
      alignItems: 'center',
      backgroundColor: 'var(--bosscat-green-100)',
      padding: '8px',
      gap: '8px',
    },
    propertyTitle: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'OutfitHeavy',
      fontSize: '32px',
      fontWeight: 900,
      lineHeight: '40px',
      textTransform: 'uppercase',
      width: 'fit-content',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    buttonIcon: {
      width: '16px',
      height: '16px',
    },
    lgFont: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      lineHeight: '24px',
    },
    closeButton: {
      padding: '16px 16px !important',
      minWidth: '40px !important',
      width: '40px !important',
      height: '40px !important',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      borderRadius: '8px',
    },
    closeButtonModal: {
      color: 'var(--text-text-tertiary)',
      cursor: 'pointer',
      '&:hover': {
        color: 'var(--black-color)',
      },
    },
    dateSelect: {
      '& li:first-child': {
        '&:after': {
          content: '""',
          display: 'block',
          borderBottom: '2px solid var(--black-color)',
          borderRadius: '8px',
          transform: 'translateY(12px)',
          width: '100%',
        },
      },
    },
    primaryButton: {
      backgroundColor: 'var(--bosscat-blue-600) !important',
      color: 'white',
      textTransform: 'none',
      borderRadius: '8px',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 3px 5px 0px rgba(9, 25, 49, 0.20)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important',
      },
    },
    primaryDarkButton: {
      backgroundColor: 'var(--bosscat-blue-900) !important',
      color: 'white',
      textTransform: 'none',
      borderRadius: '8px',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 3px 5px 0px rgba(9, 25, 49, 0.20)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-900) !important',
      },
    },
    primaryOutlinedButton: {
      border: '2px solid var(--bosscat-blue-600) !important',
      color: 'var(--bosscat-blue-600) !important',
      backgroundColor: 'white',
      borderRadius: '8px',
      textTransform: 'none',
      '&:disabled': {
        color: 'var(--bosscat-blue-300) !important',
        border: '2px solid var(--bosscat-blue-300) !important',
      },
    },
    hazeButton: {
      backgroundColor: 'var(--bosscat-blue-900) !important',
      color: 'var(--repairs-yellow-600)',
      textTransform: 'none',
      borderRadius: '8px',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 3px 5px 0px rgba(9, 25, 49, 0.20)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-1000) !important',
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important',
      },
    },
    homeDepotButton: {
      backgroundColor: '#F96302 !important',
      color: 'white',
      textTransform: 'none',
      borderRadius: '8px',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 3px 5px 0px rgba(9, 25, 49, 0.20)',
    },
    Tabs: {
      padding: '8px',
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      width: '100%',
    },
    Tab: {
      color: 'var(--bosscat-blue-900)',
      opacity: 1,
      padding: '8px',
      // border: "1px solid var(--bosscat-blue-600)",
      textTransform: 'none',
      minWidth: 'fit-content',
      minHeight: 'fit-content',
      fontSize: '18px',
      lineHeight: '24px',
      height: '44px',
      marginLeft: '8px',
      marginRight: '8px',
      '&.Mui-selected': {
        color: '#fff',
        borderRadius: '8px',
      },
    },
    navigationTabs: {
      padding: '8px',
      borderRadius: '8px 8px 0 0',
      // border: '2px solid var(--bosscat-grey-400)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      width: '100%',
    },
    mobileNavTab: {
      backgroundColor: 'white',
      color: 'var(--bosscat-blue-900)',
      flex: 1,
      '&.Mui-selected': {
        color: 'var(--bosscat-blue-900)',
        fontWeight: 700,
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 8,
          left: '50%', // Center the div under the selected tab
          transform: 'translateX(-50%)', // Centering trick
          width: '100%', // Adjust the width as needed
          height: '4px', // Adjust the height as needed
          borderRadius: '8px 8px 0 0', // Match the border radius of the tab
          backgroundColor: 'var(--text-text-info)',
        },
      },
    },
    navigationTab: {
      color: 'white',
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'white',
        color: 'var(--bosscat-blue-900)',
      },
      opacity: 1,
      padding: '8px',
      // border: "1px solid var(--bosscat-blue-600)",
      textTransform: 'none',
      minWidth: 'fit-content',
      minHeight: 'fit-content',
      fontSize: '18px',
      lineHeight: '24px',
      height: '44px',
      marginLeft: '8px',
      marginRight: '8px',
      '&.Mui-selected': {
        color: '#fff',
        [theme.breakpoints.down('md')]: {
          color: 'var(--bosscat-blue-900)',
        },
        fontWeight: 700,
        '&::after': {
          content: '""',
          position: 'absolute',
          backgroundColor: 'var(--white-color)',
          bottom: 0,
          left: '50%', // Center the div under the selected tab
          transform: 'translateX(-50%)', // Centering trick
          width: '100%', // Adjust the width as needed
          height: '4px', // Adjust the height as needed
          borderRadius: '8px 8px 0 0', // Match the border radius of the tab
          [theme.breakpoints.down('md')]: {
            backgroundColor: 'var(--text-text-info)',
          },
        },
      },
    },
    nextArtLgFont: {
      fontFamily: 'OutfitNormal',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '32px',
    },
    containerBox: {
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      overflow: 'hidden',
    },
    propertySnapshotItem: {
      padding: '14px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    homeTeam: {
      padding: '8px',
    },
    homeTeamPhoto: {
      width: '42px',
      height: '42px',
      borderRadius: '4px',
    },
    homeTeamContactContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    latoSmFont: {
      fontFamily: 'LatoNormal',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    orderButton: {
      backgroundColor: 'var(--bosscat-green-100)',
      color: 'var(--text-text-success)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-200) !important',
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-green-300) !important',
      },
    },
    scoreSelector: {
      position: 'relative',
      background:
        'linear-gradient(90deg, #0E905A -2.22%, #EBA900 50.88%, #CA0000 102.89%)',
      borderRadius: '4px',
      padding: '3px',
      minHeight: '24px',
      width: '100%',
    },
    scoreIndicator: {
      display: 'flex',
      gap: '4px',
      position: 'absolute',
      alignItems: 'center',
    },
    formContainer: {
      display: 'block !important',
      '@global': {
        '.MuiFormGroup-root': {
          display: 'block !important',
        },
      },
    },
    selectNone: {
      marginLeft: '0px',
      '@global': {
        '.MuiButtonBase-root': {
          padding: '0 0.3rem 0rem !important ',
        },
      },
    },
    sliderSelector: {
      borderRadius: 8,
      width: '100%',
      // maxWidth: "240px",
      position: 'relative',
      height: '100% !important',
    },
    radioButton: {
      position: 'absolute',
      bottom: '.5rem',
      right: '.5rem',
      backgroundColor: 'white',
      borderRadius: 20,
      '@global': {
        '.MuiButtonBase-root': {
          paddingTop: '4px !important',
          paddingBottom: '4px !important',
          paddingRight: '4px !important',
          height: '35px !important',
        },
        '.MuiTypography-root': {
          paddingRight: '.5rem',
        },
      },
    },
    slider: {
      borderRadius: '8px',
      '@global': {
        '.swiper-slide': {
          // height: 'auto',
          height: '135px !important',
          '@global': {
            img: {
              height: '100%',
              objectFit: 'cover',
            },
          },
        },
      },
    },
    arrow: {
      backgroundColor: 'rgba(26, 42, 85, 0.5) !important',
      // opacity: '50%',
      borderRadius: '100%',
      padding: '.2rem',
      '@global': {
        '.MuiSvgIcon-root': {
          // paddingLeft: '.3rem',
        },
      },
    },
    unSelectedCostTypeOption: {
      backgroundColor: 'white',
      color: 'black',
    },
    selectedBestCostTypeOption: {
      backgroundColor: 'var(--repairs-yellow-600)',
      color: 'black',
    },
    selectedBetterCostTypeOption: {
      backgroundColor: 'var(--bosscat-green-100)',
      color: 'var(--text-text-success)',
    },
    selectedGoodCostTypeOption: {
      backgroundColor: 'var(--bosscat-blue-100)',
      color: 'var(--text-text-info)',
    },
    costType: {
      padding: "2px 14px"
    },
    costTypeContainer: {
      flexWrap: 'nowrap',
    },
    noBorder: {
      borderWidth: '0 !important',
    },
    costTypeOptionContainer: {
      width: '100%',
      borderRadius: '4px',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
    },
    iconLabel: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
    },
    brandLabel: {
      fontSize: '12px',
      color: 'var(--bosscat-black-400)',
      fontWeight: 400
    },
    rowCell: {
      transition: 'background-color 0.3s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--bosscat-grey-400)',
      }
    },
    customTabsStyle: {
      '& .MuiTabScrollButton-root:first-child': {
        width: '44px',
        backgroundColor: 'var(--bosscat-blue-100)',
        borderRadius: '8px',
        fontSize: '24px',
      },
      '& .MuiTabScrollButton-root:last-child': {
        width: '44px',
        backgroundColor: 'var(--bosscat-blue-100)',
        borderRadius: '8px',
        fontSize: '24px',
      },
    },
    imagesContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative',
      '&::after': {
        content: '""',
        display: 'block',
        width: '50%',
        height: '1px',
        backgroundColor: 'var(--bosscat-blue-600)',
        position: 'absolute',
        bottom: 0,
      },
    },
    imagesLabel: {
      fontSize: '16px',
      color: 'var(--bosscat-blue-600)',
      marginRight: '4px',
    },
    imagesIcon: {
      width: '16px',
      height: '16px',
      color: "var(--bosscat-blue-600)",
    },
    emptyContainer: {
      width: '100%',
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    navigationTabsStyle: {
      backgroundColor: 'var(--bosscat-blue-900)',
      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around'
      },
      '& .MuiTabScrollButton-root:first-child': {
        width: '44px',
        backgroundColor: 'var(--bosscat-blue-800)',
        color: 'white',
        borderRadius: '8px',
        fontSize: '24px',
      },
      '& .MuiTabScrollButton-root:last-child': {
        width: '44px',
        backgroundColor: 'var(--bosscat-blue-800)',
        color: 'white',
        borderRadius: '8px',
        fontSize: '24px',
      },
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'white',
      },
    },
    arrowButton: {
      width: '48px !important',
      minWidth: '48px !important',
      height: '48px !important',
      backgroundColor: 'var(--bosscat-grey-200)',
      borderRadius: '8px',
      fontSize: '24px',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
    },
    xlFont: {
      fontFamily: 'OutfitHeavy',
      fontSize: '28px',
      fontWeight: 900,
      textTransform: 'uppercase',
      lineHeight: '40px',
    },
    portfolioView: {
      width: '100%',
      height: '48px',
      display: 'flex',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '0px',
      top: '0px',
      backgroundColor: 'var(--bosscat-blue-900)',
      gap: '1rem',
    },
    container: {
      padding: '0px 32px 0px 32px',
    },
    calendarContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    cursorLoading: {
      cursor: 'wait',
    },
    inquireButton: {
      color: 'var(--text-text-info) !important',
      backgroundColor: 'white',
      borderRadius: '8px',
      textTransform: 'none',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-grey-200) !important',
      },
    },
    inquiredButton: {
      color: 'var(--text-text-success) !important',
      backgroundColor: 'white',
      borderRadius: '8px',
      textTransform: 'none',
      boxShadow: 'none !important',
      '&:hover': {
        backgroundColor: 'white !important',
      },
    },
    navigationLoanDepotImg: {
      width: "65px"
    },
    applianceButtonLabel: {
      fontFamily: 'LatoBold',
      lineHeight: '16px',
    },
    applianceButton: {
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      borderRadius: '8px',
      minHeight: '48px !important',
      height: '44px !important',
      padding: '8px 14px !important',
      minWidth: 116,
      maxWidth: 116,
    },
    applianceButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8
    },
    orderReplacementButton: {
      backgroundColor: 'var(--bosscat-green-100) !important',
    },
    scheduleServiceButton: {
      backgroundColor: 'var(--bosscat-blue-100) !important',
    },
    navigationButton: {
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      backgroundColor: 'var(--background-bg-soft-3) !important',
      borderRadius: '8px',
      minHeight: '44px !important',
      height: '44px !important',
      padding: '8px 14px !important',
      minWidth: 160,
      maxWidth: 160,
    },
    noTransform: {
      textTransform: 'none',
      fontWeight: 400,
    },
    homeBaseLogo: {
      maxWidth: 120,
    },
    downloadAppButtonContainer: {
      position: 'fixed',
      bottom: '1rem',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      zIndex: 9999,

      '@global': {
        button: {
          textTransform: 'capitalize',
          fontFamily: 'LatoBold',
          fontSize: 18,
        },
      },
    },
    downloadAppButton: {
      display: 'flex',
      padding: '0 1rem',
      background: 'white',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      borderRadius: 8,
    },
    bottomLink: {
      color: 'var(--text-text-primary)',
      textDecoration: 'none',
    },
    endIconAbsolute: {
      width: '16px',
      height: '16px',
      position: 'absolute',
      right: '1rem',
      marginTop: '4px',
    },
    enterManuallyButtonLabel: {
      fontFamily: 'LatoBold',
      fontSize: '18px',
    },
    newApplianceButton: {
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: '8px',
      minHeight: '44px !important',
      padding: '12px 16px !important',
      minWidth: 160,
      [theme.breakpoints.down('md')]: {
        width: "100%"
      }
    },
    newApplianceText: {
      fontFamily: "LatoBold",
      fontSize: '18px',
    },
    optionApplianceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      width: '100%',
      cursor: 'pointer',
    },
    textApplianceOption: {
      color: 'var(--bosscat-black-600)',
      '&:hover': {
        color: 'var(--bosscat-black-600)',
      },
    },
    successMessage: {
      fontFamily: 'LatoBold !important',
      fontSize: '18px',
      textAlign: 'center',
    },
    applianceTextField: {
      width: '100%',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    appliancePhoto: {
      width: '100%',
      height: '240px',
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      objectFit: 'cover',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '16px',
      width: '100%',
      marginTop: '1rem',
    },
    boxQuestion: {
      backgroundColor: 'var(--bosscat-grey-200)',
      width: '100%',
      marginTop: '1rem',
      borderRadius: '8px',
      padding: '8px',
      border: '2px solid var(--border-color)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
    },
    finishSection: {
      width: '100%',
      height: '100%',
      marginTop: '1rem',
      flexDirection: 'column',
    },
    loadingSection: {
      width: '100%',
      height: '54px',
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorMessage: {
      fontFamily: 'OutfitBold',
      fontSize: '18px',
      textAlign: 'center',
    },
    errorDescription: {
      color: 'var(--bosscat-black-400)',
      fontSize: '18px',
      textAlign: 'center',
      margin: "16px 0",
      padding: "0 3rem"
    },
    brandSelect: {
      '& li:first-child': {
        '&:after': {
          content: '""',
          display: 'block',
          borderBottom: '2px solid var(--black-color)',
          borderRadius: '8px',
          transform: 'translateY(12px)',
          width: '100%',
        },
      },
    },
  })
)

