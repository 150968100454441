import { FC } from 'react'
import { Switch as MuiSwitch } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switch: {
      width: '28px !important', 
      height: '22px !important',
      padding: '2px 0px !important',
      alignSelf: 'center',
      display: 'flex !important',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)'
        }
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        top: -16,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: 'var(--bosscat-blue-600)'
          }
        }
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200
        })
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
      }
    }
  })
)

interface SwitchProps {
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
}

const Switch: FC<SwitchProps> = ({ checked, onChange, onClick }) => {
  const classes = useStyles()

  return (
    <MuiSwitch 
      className={classes.switch}
      checked={checked}
      defaultChecked={checked}
      onChange={onChange}
      onClick={onClick}
    />
  )
}

export default Switch